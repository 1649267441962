<template>
    <div id="edit-location">

        <h2 is="sui-header" class="dividing">Edit Location</h2>
        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <location-form :location="location"></location-form>
        </sui-form>

        <sui-divider></sui-divider>
        <sui-button type="submit" color="blue" @click.prevent="save">Update Location</sui-button>
        <sui-button floated="right" @click.prevent="$router.push('/dashboard/location')">Cancel</sui-button>
    </div>
</template>

<script>

    import axios from "axios";

    import LocationForm from "@/views/dashboard/location/LocationForm";
    import ErrorMessage from "@/components/ErrorMessage";
    import SuccessMessage from "@/components/SuccessMessage";

    export default {
        name: "LocationEdit",
        components: {SuccessMessage, ErrorMessage, LocationForm},
        data: function () {
            return {
                location: {
                    title: "",
                    address: "",
                    status: "Active",

                    users: [],

                    errorTitle: false,
                    errorAddress: false,
                    errorStatus: false,
                },
                loading: true,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },

        created: async function () {
            axios({
                method: "POST",
                url: "/api/location/get",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                data: {
                    token: this.$cookies.get('token'),
                    tokenId: this.$cookies.get('tokenId'),
                    id: this.$route.params.id
                }
            }).then((response) => {
                console.log(response)
                console.log(response.data.location.users);
                this.loading = false
                this.location = {
                    title: response.data.location.title,
                    address: response.data.location.address,
                    status: response.data.location.status,

                    users: response.data.location.users,

                    errorTitle: false,
                    errorAddress: false,
                    errorStatus: false,
                }
            }).catch((err) => {

                this.errorTitle = "Cannot load user profile";
                this.errorList = [err];
                this.errorList.push("Network connection issue.");
            })
        },

        methods: {
            save: async function () {
                this.location.errorTitle = this.location.title.trim() === "";
                this.location.errorAddress = this.location.address.trim() === "";
                this.location.errorStatus = this.location.status.trim() === "";

                if (!(this.location.errorTitle || this.location.errorAddress || this.location.errorStatus)) {
                    axios({
                        method: "POST",
                        url: "/api/location/update",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),

                            id: this.$route.params.id,

                            title: this.location.title,
                            address: this.location.address,
                            status: this.location.status,

                            users: this.location.users,
                        }
                    }).then((result) => {
                        if (result.data.changed) {
                            this.successTitle = "Updated location!"
                            this.errorTitle = "";
                            this.errorList = [];
                        } else {
                            this.errorTitle = "Cannot create new location!";
                            this.errorList = [];
                            this.errorList.push("Network connection issue.");
                        }

                    }).catch((err) => {
                        console.log(err);
                        this.errorTitle = "Cannot create new location";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }).finally(() => {
                        this.location.errorTitle = false;
                        this.location.errorAddress = false;
                        this.location.errorStatus = false;
                    })
                }
            }

        }
    }
</script>

<style scoped>

</style>
